import React from "react"
import { Button, Form } from 'react-bootstrap'
import Ripples from 'react-ripples'
import axios from 'axios'
import { navigate } from "gatsby"

class RequestForm extends React.Component {
	constructor(props) {
		super(props)
		this.state={
			full_name: '',
			email: '',
			mobile_no: '',
			zip_code: '',
			comment: '',
		}
		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	handleChange(event) {
		const {id, value} = event.target
		this.setState({
			[id]: value
		})
        console.log(event, "eventidvalue 00", id, value);
	}
	handleSubmit(event) {
		event.preventDefault()
        const { location } = this.props;
		const data = {
            source: location.origin,
            page: location.href,
            form: event.target.id,
			full_name: this.state.full_name,
			email: this.state.email,
			mobile_no: this.state.mobile_no,
			zip_code: this.state.zip_code,
			comment: this.state.comment,
		}
		axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.FORM_CRM_TOKEN }
    }).then((res) => {
        this.setState({source: '', page: '', form: '', full_name: '', email: '', mobile_no: '', zip_code: '', comment: ''});
        navigate("/thanks");
      }).catch((error) => {
          alert(error)
      });
	}
	render() {
        const {formId} = this.props;
	return(	
		<Form id={formId} onSubmit={this.handleSubmit}>                        
            <div className="form-group">
                <input type="text" id="full_name" className="form-control" onChange={this.handleChange} required />
                <label htmlFor="full_name" className={this.state.full_name!==''?"form-control-placeholder active": "form-control-placeholder"}>Full Name *</label>
            </div>
            <div className="form-group">
                <input type="email" id="email" className="form-control" onChange={this.handleChange} required />
                <label htmlFor="email" className={this.state.email!==''?"form-control-placeholder active": "form-control-placeholder"}>Email Address *</label>
            </div>
            <div className="form-group">
                <input type="tel" id="mobile_no" className="form-control" onChange={this.handleChange}  pattern="[0-9]{10}" required />
                <label htmlFor="mobile_no" className={this.state.mobile_no!==''?"form-control-placeholder active": "form-control-placeholder"} >Phone Number *</label>
            </div>
            <div className="form-group">
                <input type="text" id="zip_code" className="form-control" onChange={this.handleChange} required />
                <label htmlFor="zip_code" className={this.state.zip_code!==''?"form-control-placeholder active": "form-control-placeholder"}>Zip Code *</label>
            </div>
            <div className="form-group">
                <textarea id="comment" className="form-control" onChange={this.handleChange} required />
                <label htmlFor="comment" className={this.state.comment!==''?"form-control-placeholder active": "form-control-placeholder"}>Type your message here...</label>
            </div>
            <div className="form-action">
            <Ripples><Button variant="secondary" type="submit" className='btn-border'>Submit</Button></Ripples>
            </div>                   
        </Form>
	)
}
}


export default RequestForm