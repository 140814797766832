import React from "react"
import { Section } from "../Section"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { CallIcon, MailIcon, ArrowRight } from "../Icons"
const MoreInformation = props => {
  return (
    <Section className="needmore section" pb="60px" bg="#1B1464" pt="60px">
      <Container>
        <div className="section-heading" data-aos="fade-up">
          <h2 className="section-title">
            Got questions, or need more information? <br />
            Reach out to us today!
          </h2>
        </div>
        <div className="section-content">
          <div
            className="needmore-outer"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="o-circle">OR</div>
            <Row noGutters className="needmore-inner align-items-center">
              <Col lg={6}>
                <div className="needmore-inner-item">
                  <span className="icon-box">
                    <CallIcon stroke="#CB0000" width="60px" height="60px" />
                  </span>
                  <h3 className="heading-text">Call us now</h3>
                  <p>Give us a call, we’re here to help!</p>
                  <a
                    href="tel:8773304846"
                    className="btn btn-link phone-number bottom-link"
                  >
                    (877) 330-4846
                  </a>
                </div>
              </Col>
              <Col lg={6}>
                <div className="needmore-inner-item right">
                  <span className="icon-box">
                    <MailIcon stroke="#fff" width="60px" height="60px" />
                  </span>
                  <h3 className="heading-text">Shoot us an email</h3>
                  <p>You can email us to get the ball rolling!</p>
                  <Link
                    className="btn btn-link text-white bottom-link icon-right"
                    to="/contact"
                    size="sm"
                  >
                    Contact Us{" "}
                    <span>
                      <ArrowRight
                        stroke="#fff"
                        ArrowDir="right"
                        width="14px"
                        height="14px"
                      />
                    </span>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default MoreInformation
